.login-page {
    position: relative;
    z-index: 0;
    overflow: hidden;
    background-color: #2255a4;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigation-active {
    color: #fff;
}

.login-page::before {
    position: absolute;
    right: -100px;
    top: -150px;
    width: 370px;
    height: 370px;
    background-color: #6569cc;
    content: '';
    z-index: -1;
    border-radius: 50%;
    opacity: .5;
}

.login-page:after {
    position: absolute;
    right: -100px;
    top: -150px;
    width: 340px;
    height: 340px;
    background-color: #656ccc;
    content: '';
    z-index: -1;
    border-radius: 50%;
    opacity: .5;
}

.login-box {
    border: 3px solid #fff;
    border-radius: 20px;
    padding: 30px 20px;
}

.login-box h3 {
    color: #fff;
}

.input-group {
    width: 360px;
}

.input-control {
    width: 100%;
    height: 52px;
    position: relative;
    margin: 10px 0;
}


.input-control input {

    outline: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    border-bottom: 2px solid #fff;
    padding: 0px 10px 0px 30px;
    font-weight: 500;
    color: #fff;
    font-size: 18px;
}

.input-control input::placeholder {
    color: #fff;
}

.input-control i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    margin-left: 6px;
}

.input-control.password button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    color: #0a66c2;
    border: none;
}




.input-groups .submit-btn button {
    height: 46px;
    width: 100%;
    display: inline-block;
    background: #0a66c2;
    font-size: 16px;
    color: #fff;
    border: none;
    border-radius: 40px;
    margin-top: 20px;
    font-weight: bold;
    transition: 0.4s;
}

.input-groups .submit-btn button:hover {
    background: #0856a5;
}


/* input control label */
.input-control-label {
    position: relative;
}

.input-control-label input {
    width: 100%;
    height: 32px;
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 0 10px;
}

.input-control-label span {
    font-size: 14px;
}

.login-btn {
    border-radius: 6px !important;
    border: 2px solid #fff;
    padding: 6px 12px;
    background: transparent;
    color: #fff;
    display: flex;
    transition: 0.4s;
    margin: 10px 0;
    font-weight: 700;
}

.login-btn:hover {
    color: #0856a5;
    background: #fff;
}

.input-group .alert-message {
    font-weight: 600 !important;
    font-size: 18px;
}

.otp-main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    background-color: rgba(0, 0, 0, .9);
    color: #000;
    text-align: center;
}

.otp-main .otp-box {
    backdrop-filter: blur(9px);
    padding: 30px 20px;
    background-color: rgba(255, 255, 255, .6);
    border-radius: 40px;
}

.otp-box input {
    margin-bottom: 10px;
    width: 100%;
}

.swal2-container.swal2-center.swal2-backdrop-show {
    z-index: 99999 !important;
}

textarea {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    color: #fff;
    background-color: transparent;
}



.chat-sec {
    padding: 0 10px;
    max-height: 100%;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.chat-sec .dummy {
    width: 100%;
    height: 70px;
}

.chat-sec .box {
    max-width: 90%;
    min-width: 50%;
    color: #000;
    margin: 10px 0;
    padding: 5px 8px;
    border-radius: 5px;
    position: relative;
    padding-bottom: 25px;
}

.chat-sec .box .time {
    position: absolute;
    color: #fff;
    right: 4px;
    bottom: 2px;
}

.chat-sec .sender {
    display: flex;
    justify-content: flex-start;
}

.chat-sec .sender .box {
    background-color: #5048e5;
    color: #fff;
}

.chat-sec .reciver {
    display: flex;
    justify-content: flex-end;

}

.chat-sec .reciver .box {
    background-color: #10b981;
    color: #fff;
}