@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.child-message{
  /* width:50%; */
  gap: 10px;;
  border-radius: 20px;
  /* transform: translate(-50%, -50%); */
}

@media screen and (max-width:600px){
  .child-message{
    /* width:98%; */
    gap: 10px;;
    border-radius: 20px;
  /* transform: translate(-50%, -50%); */
  }
}
.admin-m{
  background-color: rgb(37, 37, 223);
  max-width: 40%;
  color:white;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
.user-m{
  background-color: lightgrey;
  color:black;
  max-width: 40%;
  padding: 10px;
  border-radius: 20px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.products .remove {
  font-size: 12px;
  padding: 4px 8px;
}

.farmer-store .card-img-top {
  width: 100% !important;
}

.farmer-store .add-items .btn {
  margin: 0 !important;
}


.card-wrap {
  padding: 5px  10px;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  scroll-snap-type: y mandatory;
}

.product {
  width: 100%;
  padding: 10px;
  margin: 10px 0px;
  border: 1px solid rgba(128, 128, 128, 0.568);
  scroll-snap-align: start;
}

.pro-img {
  margin: auto;
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 2px;
}

.pro-detail {
  padding: 4px 14px;
}

.pro-detail>* {
  margin: 4px 2px;
}

.product .remove {
  font-size: 14px;
}

.pro-price, .pro-desc, .pro-name {
  font-size: 15px;
}

.card-wrap {
  display: flex;
  margin: 10px 50px;
  overflow-x: scroll;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
}
.img-box-pro{
  position: relative;
}
.img-box-pro .green-box {
  position: absolute;
  top: 0;
  right: 5px;
  height: 20px;
  width: 20px;
  background-color: green;
  border-radius: 4px;
}

.img-box-pro .green-box::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #fff;
}

@media (max-width:611px) {
  .card-wrap {
    flex-wrap: wrap;
    margin: 10px 5px;
  }

  .Home .card {
    scroll-snap-align: start;
  }

  .card-body {
    text-align: left;
    width: 150px;
  }

  .products {
    min-width: 150px;
  }

  .cart .products {
    display: flex;
    flex-direction: row;
  }

  .products .cart-img {
    display: flex;
    align-items: center;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent;
    -webkit-text-fill-color: #fff !important;
    color: #fff;
}